<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
		<ng-template carouselSlide>

			
			<div class="home" [ngClass]="textClass"
				[ngStyle]="{'background-image': 'url('+(isMobile ? slider.Mobile_ImageUrl :slider.ImageUrl)+')'}">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="slider-contain">
								<div>
									<h4 [ngStyle]="{'color': slider.Color_SubTitle}">
										{{isArabic ? slider.AR_SubTitle: slider.EN_SubTitle}}</h4>
									<h2 [ngStyle]="{'color': slider.Color_Title}">
										{{isArabic ? slider.AR_Title: slider.EN_Title}}</h2>
									<a [href]=" (isArabic ? slider.AR_Link : slider.EN_Link)" class="btn btn-solid"
										[ngClass]="buttonClass">
										{{isArabic ? slider.AR_ButtonTitle: slider.EN_ButtonTitle}}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</owl-carousel-o>