import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { CategoryModel } from '../Models/categoryModel';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public apiPath;
  constructor(private http: HttpClient,
    settingService: SettingsService) {
    this.apiPath = settingService.apiPath;
  }

  getCategories(): Observable<CategoryModel[]> {
    var url = `${this.apiPath}/categories/select`;
    return this.http.get<CategoryModel[]>(url);
  }

  getMainCategories(): Observable<{ count: number, rows: CategoryModel[] }> {
    var url = `${this.apiPath}/categories/main`;
    return this.http.get<{ count: number, rows: CategoryModel[] }>(url);
  }

  getCategoryWithProducts(id): Observable<{ count: number, rows: CategoryModel[] }> {
    var url = `${this.apiPath}/categories/select/categoryWithProducts/${id}`;
    return this.http.get<{ count: number, rows: CategoryModel[] }>(url);
  }


  public getCategoryByID(id: string): Observable<CategoryModel> {
    return this.http.get<CategoryModel>(`${this.apiPath}/categories/select/byID/${id}`);
  }

}
