import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VisitorModel } from '../Models/visitorModel';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorsService {
  apiPath;
  constructor(
    private http: HttpClient,
    settingService: SettingsService) {
    this.apiPath = settingService.apiPath;
  }

  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  addVisit(itemID, itemType: Type, isArabic: boolean, path: string) {
    var addPath;
    switch (itemType) {
      case Type.Home: addPath = "addHome"; break;
      case Type.Product: addPath = "addProduct"; break;
      case Type.Category: addPath = "addCategory"; break;
      case Type.Tag: addPath = "addTag"; break;
      case Type.Color: addPath = "addColor"; break;
      case Type.Material: addPath = "addMaterial"; break;
      case Type.Part: addPath = "addPart"; break;
    }

    var visit: VisitorModel = {
      ItemID: itemID,
      ItemType: itemType,
      Lang: isArabic ? "ar" : "en",
      Path: path,
    }

    const IpAddress = localStorage.getItem("IpAddress")

    if (IpAddress) {
      // console.log("Cashed")
      visit.IPAddress = IpAddress;
      var url = `${this.apiPath}/visitors/${addPath}`;
      this.http.post(url, visit).subscribe(e => { })
    }
    else {
      this.getIPAddress().subscribe((res: any) => {
        localStorage.setItem("IpAddress", res.ip);;
        visit.IPAddress = res.ip;

        var url = `${this.apiPath}/visitors/${addPath}`;
        this.http.post(url, visit).subscribe(e => { })
      });
    }

  }
}

export enum Type {
  Home = 1,
  Product = 2,
  Category = 3,
  Tag = 4,
  Color = 5,
  Material = 6,
  Part = 7
}