import { Component, OnInit } from '@angular/core';
import { ProductModel } from 'src/app/Models/productModel';
import { CategoryModel } from 'src/app/Models/categoryModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ProductsService } from 'src/app/Services/products.service';
import { CategoryService } from 'src/app/Services/category.service';
import { SettingsService } from 'src/app/Services/settings.service';
import { SEOService } from 'src/app/Services/seo.service';
import { VisitorsService, Type } from 'src/app/Services/visitors.service';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit {

  public grid: string = 'col-xl-3 col-md-6';
  public layoutView: string = 'grid-view';
  public products: ProductModel[];
  public pageNo: number = 1;
  public perPage: number = 20;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;

  public isArabic;
  public category: CategoryModel;
  public categoryName: string;
  public title: string;
  lang: string;
  categoryID: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewScroller: ViewportScroller,

    productService: ProductsService,
    categoryService: CategoryService,
    settingsService: SettingsService,
    seoService: SEOService,
    visitorService: VisitorsService,
  ) {

    this.route.paramMap.subscribe(params => {
      this.categoryID = params.get('id');
      this.lang = params.get('lang');
      const name = params.get('name');
      this.isArabic = this.lang == "ar";
      this.title = this.isArabic ? settingsService.ar_Title : settingsService.en_Title;

      settingsService.ChangeDirection(this.isArabic);

      //Get Category By ID;
      categoryService.getCategoryByID(this.categoryID).subscribe(cate => {
        this.category = cate;
        this.categoryName = this.isArabic ? this.category.AR_Name : this.category.EN_Name;
        var description = this.isArabic ? this.category.AR_Description : this.category.EN_Description;
        seoService.updateTitle(`${this.categoryName} - ${this.title}`);
        seoService.updateDescription(`${description}`);

        const currentPath = `/${this.lang}/category/${this.categoryID}/${name}`;
        const path = this.isArabic ? this.category.AR_Path : this.category.EN_Path;
        if (currentPath !== path) {
          console.log("Not Equals");
          router.navigate([path]);
          return;
        }

        // Get Query params..
        this.route.queryParams.subscribe(params => {
          this.sortBy = params.sortBy ? params.sortBy : 'ascending';
          this.pageNo = params.page ? params.page : this.pageNo;

          // //Get Product By ID;
          productService.getProductsByCategory(this.categoryID, this.perPage, this.perPage * (this.pageNo - 1)).subscribe(products => {

            // Sorting Filter
            this.products = productService.sortProducts(products.rows, this.sortBy);

            // // Paginate Products
            this.paginate = productService.getPager(products.count, +this.pageNo, this.perPage);     // get paginate object from service
            // this.products = this.products.slice(this.paginate.startIndex, this.paginate.endIndex + 1); // get current page of items

            //Add Visit 
            visitorService.addVisit(this.categoryID, Type.Category, this.isArabic, path);
          });
        })
      })
    });
  }

  ngOnInit(): void {
  }


  // Append filter value to Url
  updateFilter(tags: any) {
    tags.page = null; // Reset Pagination
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: tags,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // SortBy Filter
  sortByFilter(value) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: value ? value : null },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if (value == 'list-view')
      this.grid = 'col-lg-12';
    else
      this.grid = 'col-xl-3 col-md-6';
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  SetProductPath(product) {
    const name: string = (this.isArabic ? product?.AR_Name : product?.EN_Name);
    const path = name.trim().split(' ').join('-');
    return `/${this.lang}/p/${product.ProductID}/${path}`;
  }
}
