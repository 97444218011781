import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './home/pages/homepage/homepage.component';
import { ProductPageComponent } from './home/pages/product-page/product-page.component';
import { CategoryPageComponent } from './home/pages/category-page/category-page.component';
import { ColorPageComponent } from './home/pages/color-page/color-page.component';
import { MaterialPageComponent } from './home/pages/material-page/material-page.component';
import { TagPageComponent } from './home/pages/tag-page/tag-page.component';
import { BranchPageComponent } from './home/pages/branch-page/branch-page.component';

const routes: Routes = [

  {
    path: ':lang',
    component: HomepageComponent,
    pathMatch: 'full'
  },
  {
    path: ':lang/product/:id',
    component: ProductPageComponent,
  },
  {
    path: ':lang/product/:id/:name',
    component: ProductPageComponent,
  },

  {
    path: ':lang/category/:id',
    component: CategoryPageComponent,
  },
  {
    path: ':lang/category/:id/:name',
    component: CategoryPageComponent,
  },


  {
    path: ':lang/color/:id',
    component: ColorPageComponent,
  },
  {
    path: ':lang/color/:id/:name',
    component: ColorPageComponent,
  },
  {
    path: ':lang/material/:id',
    component: MaterialPageComponent,
  },
  {
    path: ':lang/material/:id/:name',
    component: MaterialPageComponent,
  },

  {
    path: ':lang/tag/:id',
    component: TagPageComponent,
  },
  {
    path: ':lang/tag/:id/:name',
    component: TagPageComponent,
  },

  {
    path: ':lang/branch/:id',
    component: BranchPageComponent,
  },
  {
    path: ':lang/branch/:id/:name',
    component: BranchPageComponent,
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: '/ar',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
