import { Component, Input, HostListener } from '@angular/core';
import { HomeSlider } from '../../../Services/slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;
  @Input() isArabic: boolean;

  constructor() {
    this.isMobile = window.innerWidth < 700;
  }

  isMobile;
  public HomeSliderConfig: any = HomeSlider;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 700;
  }

}
