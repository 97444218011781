<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <!-- <div class="top-header top-header-dark2" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h6 class="header-contact wordCarousel">
                        <div>
                            <ul class="flip2">
                                <li *ngFor="let news of newsList">
                                    <a *ngIf="isArabic ? news.AR_Link : news.EN_Link"
                                        [href]="isArabic ? news.AR_Link : news.EN_Link">
                                        {{isArabic ? news.AR_Name: news.EN_Name}}
                                    </a>
                                    <a *ngIf="!(isArabic ? news.AR_Link : news.EN_Link)">
                                        {{isArabic ? news.AR_Name: news.EN_Name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </h6>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <div class="logo">
                            <a [routerLink]="['/',isArabic? 'ar' : 'en']" id="headerlogo">
                                <img [src]="themeLogo" class="img-fluid" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu [isArabic]="isArabic"></app-menu>
                        </div>
                        <!-- <div>
                            <app-settings></app-settings>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>