import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';

import  '@squadette/hammerjs';
import 'mousetrap';
import { Ng5SliderModule } from 'ng5-slider';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { SliderComponent } from './home/widgets/slider/slider.component';
import { HomepageComponent } from './home/pages/homepage/homepage.component';
import { CategoryWidgetComponent } from './home/widgets/category-widget/category-widget.component';
import { ProductPageComponent } from './home/pages/product-page/product-page.component';
import { TagPageComponent } from './home/pages/tag-page/tag-page.component';
import { CategoryPageComponent } from './home/pages/category-page/category-page.component';
import { ColorPageComponent } from './home/pages/color-page/color-page.component';
import { MaterialPageComponent } from './home/pages/material-page/material-page.component';
import { CategoriesListComponent } from './home/components/categories-list/categories-list.component';
import { ProductBoxComponent } from './home/components/product-box/product-box.component';
import { ProductBoxVerticalSliderComponent } from './home/components/product-box-vertical-slider/product-box-vertical-slider.component';
import { SettingsComponent } from './home/components/settings/settings.component';
import { MenuComponent } from './home/components/menu/menu.component';
import { BreadcrumbComponent } from './home/components/breadcrumb/breadcrumb.component';
import { LeftMenuComponent } from './home/components/left-menu/left-menu.component';
import { ProductQuickViewComponent } from './home/components/product-quick-view/product-quick-view.component';
import { GridComponent } from './home/components/grid/grid.component';
import { PaginationComponent } from './home/components/pagination/pagination.component';
import { SkeletonProductBoxComponent } from './home/components/skeleton/skeleton-product-box/skeleton-product-box.component';
import { TapToTopComponent } from './home/components/tap-to-top/tap-to-top.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HeaderComponent } from './home/widgets/header/header.component';
import { FooterComponent } from './home/widgets/footer/footer.component';
import { SocialComponent } from './home/components/social/social.component';
import { BranchPageComponent } from './home/pages/branch-page/branch-page.component';
import { HeaderMoblianaComponent } from './home/widgets/header-mobliana/header-mobliana.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [
    AppComponent,
    SliderComponent,
    HomepageComponent,
    CategoryWidgetComponent,
    ProductPageComponent,
    TagPageComponent,
    CategoryPageComponent,
    ColorPageComponent,
    MaterialPageComponent,
    CategoriesListComponent,

    ProductBoxComponent,
    ProductBoxVerticalSliderComponent,
    MenuComponent,
    BreadcrumbComponent,
    CategoriesListComponent,
    LeftMenuComponent,
    ProductQuickViewComponent,
    GridComponent,
    SettingsComponent,
    PaginationComponent,
    SkeletonProductBoxComponent,
    TapToTopComponent,
    HeaderComponent,
    HeaderMoblianaComponent,
    FooterComponent,
    SocialComponent,
    BranchPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),


    AppRoutingModule,
    Ng5SliderModule,
    CarouselModule,
    GalleryModule.forRoot(),
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
  ],
  // providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]

})
export class AppModule { }
