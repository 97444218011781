<!-- Home slider start-->
<section class="p-0">
    <app-slider [sliders]="slidersList" [textClass]="'text-center'" [isArabic]="isArabic">
    </app-slider>
</section>
<!-- Home slider End -->

<div class="container box-layout bg-image" [ngStyle]="{'background-image': 'url(assets/images/bg-image.jpg)'}">

    <!-- category -->
    <section class="section-t-space">
        <div class="container">
            <div class="row">
                <div class="col">
                    <app-category-widget [categories]="categoriesList" [isArabic]="isArabic"></app-category-widget>
                </div>
            </div>
        </div>
    </section>
    <!-- category end -->


    <!-- Product slider start-->
    <section class="section-b-space">
        <div class="container">
            <div class="title1">
                <h4>
                    {{ 'our-products' | translate }}
                </h4>
                <h2 class="title-inner1">
                    {{ 'new-collection' | translate }}
                </h2>
            </div>
            <div class="row">
                <div class="col-xl-6 offset-xl-3">
                    <div class="product-para">
                        <p class="text-center">
                            {{ 'new-collection-description' | translate }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <owl-carousel-o class="product-m" [options]="ProductSliderConfig">
                        <ng-container *ngFor="let product of specialProducts | slice:0:8">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <app-product-box [product]="product" [isArabic]="isArabic">
                                    </app-product-box>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>

                    <owl-carousel-o class="product-m" [options]="ProductSliderConfig">
                        <ng-container *ngFor="let product of specialProducts | slice:8:16">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <app-product-box [product]="product" [isArabic]="isArabic">
                                    </app-product-box>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>

                    <owl-carousel-o class="product-m" [options]="ProductSliderConfig">
                        <ng-container *ngFor="let product of specialProducts | slice:16:24">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <app-product-box [product]="product" [isArabic]="isArabic">
                                    </app-product-box>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>

                </div>
            </div>
        </div>
    </section>
    <!-- Product slider End -->

    <!-- Parallax banner start-->
    <section class="p-0">
        <div class="full-banner parallax-banner1 parallax text-center p-left"
            [ngStyle]="{'background-image': 'url(assets/images/parallax/1.jpg)'}">

            <div class="container">
                <div class="row">
                    <div class="col-lg-6" *ngFor="let part of partsList">
                        <div class="banner-contain">
                            <h3>{{isArabic ? part.AR_Name : part.EN_Name}}</h3>
                            <h4>{{isArabic ? part.AR_Address : part.EN_Address}}</h4>
                            <iframe [src]="part.MapPath" width="100%" height="400px" frameborder="0" style="border:0;"
                                allowfullscreen="" aria-hidden="false" tabindex="0">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Parallax banner End -->


    <!-- Tab product start-->
    <div class="title1 section-t-space">
        <h4> {{ 'exclusive' | translate }} </h4>
        <h2 class="title-inner1"> {{ 'special' | translate }}</h2>
    </div>
    <section class="section-b-space p-t-0">
        <div class="container">
            <div class="row">
                <div class="col theme-tab">
                    <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">

                        <ngb-tab *ngFor="let category of specialProductCategories">
                            <ng-template ngbTabTitle>{{isArabic ? category.AR_Name : category.EN_Name}}</ng-template>
                            <ng-template ngbTabContent>
                                <div class="no-slider row">
                                    <ng-container *ngFor="let product of getCollectionProducts(category) | slice:0:8">
                                        <div class="product-box">
                                            <app-product-box [product]="product" [isArabic]="isArabic">
                                            </app-product-box>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </section>
    <!-- Tab product End -->
</div>