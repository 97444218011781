import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, NavService } from 'src/app/Services/nav.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService) {
    // this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems );
    // this.router.events.subscribe((event) => {
    //   this.navServices.mainMenuToggle = false;
    // });
  }

  ngOnInit(): void {
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
