<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="product?.ImageUrl ? product?.ImageUrl : ImageSrc" class="img-fluid"
                            alt="{{imgAlt}}">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2> {{ (isArabic ? product?.AR_Name : product?.EN_Name ) | titlecase }}</h2>
                        <h3 *ngIf="product?.Price > 0">
                            <span class="money">
                                {{'price' | translate }} : {{product?.Price | number}} {{'currency' |  translate}}
                                <del class="product-before-discount" *ngIf="product?.PriceBeforeDiscount">
                                    {{ product?.PriceBeforeDiscount | number }} {{'currency' |  translate}}</del>
                            </span>
                        </h3>
                        <!-- <ul class="color-variant" *ngIf="Color(product.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product.variants)"
                                [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                            </li>
                        </ul> -->
                        <div class="border-product">
                            <h6 class="product-title">{{'product-details' | translate }}</h6>
                            <p>
                                {{ (isArabic ? product?.AR_Description : product?.EN_Description ).substring(0, 250)+'...' | titlecase }}
                            </p>
                        </div>
                        <div class="product-description border-product">
                            <!-- <div class="size-box" *ngIf="Size(product.Images).length">
                                <ul>
                                    <li *ngFor="let size of Size(product.Images)">
                                        <a href="javascript:void(0)">{{ size | titlecase }}</a>
                                    </li>
                                </ul>
                            </div> -->
                            <!-- <h5 class="avalibility" *ngIf="counter <= 1"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > 1"><span>Out of Stock</span></h5> -->
                            <!-- <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                        <div class="product-buttons">
                            <a [routerLink]="[path]" class="btn btn-solid">{{'view-details' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>