<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block">
            <span class="lable3" *ngIf="product.IsNew">new</span>
            <span class="lable4" *ngIf="product.IsSale">on sale</span>
        </div>
        <div class="front">
            <a [routerLink]="isArabic ? product.AR_Path : product.EN_Path">
                <img [defaultImage]="ImageSrc" [lazyLoad]="product?.ImageUrl ? product?.ImageUrl : ImageSrc"
                    class="img-fluid lazy-loading" alt="{{isArabic ? product.AR_Name : product.EN_Name}}" />
            </a>
        </div>
        <div class="back">
            <a [routerLink]="isArabic ? product.AR_Path : product.EN_Path">
                <img [src]="product.FlipImageUrl? product.FlipImageUrl : ImageSrc" class="img-fluid lazy-loading"
                    alt="{{isArabic ? product.AR_Name : product.EN_Name}}">
            </a>
        </div>
        <div class="cart-info cart-wrap  hover-items">
            <!-- <a href="javascript:void(0)" [title]="'like-product' | translate" (click)="like()">
                <i class="ti-heart icon" aria-hidden="true"></i>
            </a> -->
            <a href="javascript:void(0)" [title]="'quick-view' | translate" (click)="QuickView.openModal()">
                <i class="ti-eye icon" aria-hidden="true"></i>
            </a>
            <!-- <a [routerLink]="isArabic ? product.AR_Path : product.EN_Path" [title]="'view-product' | translate">
                <i class="ti-reload icon" aria-hidden="true"></i>
            </a> -->
        </div>
    </div>

    <div class="product-detail">
        <div>
            <!-- <bar-rating [rate]="product?.Rate" [readOnly]="true" [theme]="'fontawesome-o'"></bar-rating> -->
            <a [routerLink]="isArabic ? product.AR_Path : product.EN_Path">
                <h4 class="product-title center">
                    {{ (isArabic ? product?.AR_Name : product?.EN_Name ) | titlecase }}
                </h4>
                <!-- <span
            class="product-discrption">{{ (isArabic ? product?.AR_Description : product?.EN_Description ).substring(0, 250)+'...' | titlecase }}
          </span> -->
            </a>

            <h6 class="product-price center" *ngIf="product?.Price > 0">
                <span class="money">
                    {{'price' | translate }} : {{product?.Price | number}} {{'currency' |  translate}}
                    <del class="product-before-discount" *ngIf="product?.PriceBeforeDiscount">
                        {{ product?.PriceBeforeDiscount | number }} {{'currency' |  translate}}</del> </span>

                <!-- <span *ngIf="product?.Discount" class="product-discount"> {{'product-saved' | translate }} :
            {{product?.Discount | number }} {{'currency' |  translate}} </span> -->
            </h6>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-product-quick-view #quickView [product]="product" [isArabic]="isArabic"
    [path]="isArabic ? product.AR_Path : product.EN_Path" [ImageSrc]="ImageSrc"
    [imgAlt]="isArabic ? product.AR_Name : product.EN_Name"></app-product-quick-view>