import { Component, OnInit } from '@angular/core';
import { AdvancedLayout, Image } from '@ks89/angular-modal-gallery';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductModel } from 'src/app/Models/productModel';
import { PlainGalleryConfiguration, ButtonsConfiguration } from 'src/app/Services/portfolio';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider, ProductSlider } from 'src/app/Services/slider';
import { ProductsService } from 'src/app/Services/products.service';
import { SettingsService } from 'src/app/Services/settings.service';
import { SEOService } from 'src/app/Services/seo.service';
import { TagModel } from 'src/app/Models/TagModel';
import { MaterialModel } from 'src/app/Models/materialModel';
import { ColorModel } from 'src/app/Models/colorModel';
import { Type, VisitorsService } from 'src/app/Services/visitors.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {


  public product: ProductModel;
  public productName: string;
  public SocialPath: string;

  public activeSlide: any = 0;
  public mobileSidebar: boolean = false;
  public tags: TagModel[];
  public materials: MaterialModel[];
  public colors: ColorModel[];

  public isArabic;
  public title: string;
  apiPath: string;
  defaultImage = "assets/images/product/placeholder.jpg";
  relatedProducts;

  public GalleryConfig: any = PlainGalleryConfiguration;
  public ButtonsConfig: any = ButtonsConfiguration;
  public Images: Image[] = [];

  // @ViewChild("sizeChart") SizeChart: SizeModalComponent;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  public ProductSliderConfig: any = ProductSlider;

  constructor(
    private route: ActivatedRoute,
    router: Router,
    public productService: ProductsService,
    public settingsService: SettingsService,
    seoService: SEOService,
    visitorService: VisitorsService,
  ) {

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      const lang = params.get('lang');
      const name = params.get('name');
      this.apiPath = productService.apiPath + "/";

      this.isArabic = lang == "ar";
      settingsService.ChangeDirection(this.isArabic);

      //Get Product By ID;
      productService.getProductByID(id).subscribe(p => {
        if (p[0]) {
          this.product = p[0];
          const currentPath = `/${lang}/product/${id}/${name}`;
          const path = this.isArabic ? this.product.AR_Path : this.product.EN_Path;
          this.SocialPath = (settingsService.sitePath + path).split('\\').join('/');
          if (currentPath !== path) {
            console.log("Not Equals");
            router.navigate([path]);
            return;
          }

          this.productName = this.isArabic ? this.product.AR_Name : this.product.EN_Name;
          this.title = this.isArabic ? settingsService.ar_Title : settingsService.en_Title;
          var description = this.isArabic ? this.product.AR_Description : this.product.EN_Description;

          seoService.updateTitle(`${this.productName} - ${this.title}`);
          seoService.updateDescription(`${description}`);

          this.tags = p[0].Tags.filter(e => e.Tag && e.Tag.IsArabic == this.isArabic);

          this.materials = p[0].Materials.filter(e => e.Material);
          this.colors = p[0].Colors.filter(e => e.Color);

          var i = 0;
          this.Images = [];
          this.Images.push(new Image(i++, { img: this.product.ImageUrl }))
          this.Images.push(new Image(i++, { img: this.product.FlipImageUrl }))

          this.product.Images?.forEach(e => {
            e.ImageUrl = this.apiPath + e.ImageUrl;
            this.Images.push(new Image(i++, { img: e.ImageUrl }))
          });

          if (this.Images.length < 1) {
            this.Images.push(new Image(i++, { img: this.defaultImage }))
          }

          visitorService.addVisit(this.product.ProductID, Type.Product, this.isArabic, path);

          if (this.product.Colors && this.product.Colors[0]) {
            this.productService.getProductsByColor(this.product.Colors[0].ColorID, 10, 0).subscribe(e => {
              if(e.rows.length > 4)
                this.relatedProducts = e.rows;
            })
          }
        }
        else {
          router.navigate(['/', lang]);
        }
      });
    });
  }

  ngOnInit(): void {
  }

  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  openImage(image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.Images);
    this.GalleryConfig = Object.assign({}, this.GalleryConfig, {
      layout: new AdvancedLayout(index, true)
    });
  }

  getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  };

}
