import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CategoryModel } from 'src/app/Models/categoryModel';
import { ProductModel } from 'src/app/Models/productModel';
import { SliderModel } from 'src/app/Models/sliderModel';
import { PartModel } from 'src/app/Models/partModel';
import { ProductSlider } from 'src/app/Services/slider';
import { ProductsService } from 'src/app/Services/products.service';
import { SettingsService } from 'src/app/Services/settings.service';
import { CategoryService } from 'src/app/Services/category.service';
import { SEOService } from 'src/app/Services/seo.service';
import { Type, VisitorsService } from 'src/app/Services/visitors.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {

  public specialProducts: ProductModel[] = [];
  public categoriesList: CategoryModel[] = [];
  public slidersList: SliderModel[] = [];
  public partsList: PartModel[] = [];

  public specialProductCategories: CategoryModel[] = [];
  public ProductSliderConfig: any = ProductSlider;
  public isArabic = true;
  apiPath;
  constructor(
    public productsService: ProductsService,
    public settingsService: SettingsService,
    public categoryService: CategoryService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private visitorService: VisitorsService,
    seoService: SEOService,
  ) {
    this.route.paramMap.subscribe(params => {

      const path = document.location.href;
      this.isArabic = path.indexOf("/en") <= 0;
      settingsService.ChangeLanguage(this.isArabic ? "ar" : "en");
      var title = this.isArabic ? settingsService.ar_Title : settingsService.en_Title;
      var homeTitle = this.isArabic ? settingsService.ar_HomeTitle : settingsService.en_HomeTitle;
      var description = this.isArabic ? settingsService.ar_Desctription : settingsService.en_Desctription;
      this.apiPath = this.categoryService.apiPath + "/";

      this.settingsService.ChangeDirection(this.isArabic);
      seoService.updateTitle(`${title} ${homeTitle}`);
      seoService.updateDescription(`${description}`);
    });

    this.InitailCategories();
    this.InitailSliders();
    this.InitailParts();
  }

  private InitailCategories() {
    this.categoryService.getCategories().subscribe(response => {
      this.categoriesList = response.filter(e => e.ParentID != 0);

      this.InitailSpecialProducts();
    });
  }

  private InitailSliders() {
    this.settingsService.getSlider().subscribe(response => {
      this.slidersList = response;
    });
  }

  private InitailParts() {
    this.settingsService.getParts().subscribe(response => {
      this.partsList = response.filter(e => e.MapPath && e.MapPath != null);
      //Add Visit 
      this.visitorService.addVisit(0, Type.Home, this.isArabic, this.isArabic ? "/ar" : "/en");

      this.partsList.forEach(e => {
        e.MapPath = this.sanitizer.bypassSecurityTrustResourceUrl(e.MapPath);
      });
    });
  }

  private InitailSpecialProducts() {
    this.productsService.getSpecialProducts().subscribe(response => {
      this.specialProducts = response;
      // Get Product Collection
      this.specialProducts.filter((item) => {
        if (!this.specialProductCategories.find(e => e.CategoryID == item.CategoryID)) {
          var cate = this.categoriesList.find(e => e.CategoryID == item.CategoryID);
          this.specialProductCategories.push(cate);
        }
      });

    });
  }

  ngOnInit(): void {
    document.body.classList.add('box-layout-body');
  }


  ngOnDestroy() {
    document.body.classList.remove('box-layout-body');
  }

  // Product Tab collection
  getCollectionProducts(category: CategoryModel) {
    //Add category ID;
    return this.specialProducts.filter((item) => {
      if (item.CategoryID === category.CategoryID) {
        return item
      }
    })
  }
}