import { Component, OnInit, Input } from '@angular/core';
import { CategoryModel } from 'src/app/Models/categoryModel';
import { CategoryService } from 'src/app/Services/category.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  public categories: CategoryModel[] = [];
  public collapse: boolean = true;
  @Input() isArabic: string;

  constructor(private categoryService: CategoryService) {
  }

  private InitailCategories() {
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response.filter(e => e.ParentID != 0);
    });
  }

  ngOnInit(): void {
    this.InitailCategories();
  }

}
