<app-breadcrumb [title]="productName" [breadcrumb]="title"></app-breadcrumb>

<!-- section start -->
<section class="section-b-space" *ngIf="product">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="filter-main-btn mb-2">
                  <span class="filter-btn" (click)="toggleMobileSidebar()">
                    <i class="fa fa-bars" aria-hidden="true"></i> sidebar
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 portfolio-section grid-portfolio">
                <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="product-slick">
                  <ng-container *ngFor="let image of Images; index as i">
                    <ng-template carouselSlide [id]="i">

                      <div class="isotopeSelector">

                        <div class="overlay">
                          <div class="border-portfolio">
                            <a href="javascript:void(0)">
                              <div class="overlay-background" (click)="openImage(image)">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </div>
                              <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="image.modal.img"
                                [alt]="productName" class="img-fluid img-big" [src]="image.modal.img">
                            </a>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
                <!-- -->

                <div class="col-12 p-0">
                  <div class="slider-nav">
                    <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                      <ng-container *ngFor="let image of product?.Images; index as i">
                        <ng-template carouselSlide [id]="i">
                          <div class="owl-thumb" [class.active]="i == activeSlide">


                            <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="image?.ImageUrl"
                              [alt]="productName" class="img-fluid img-small" [src]="image?.ImageUrl"
                              (click)="owlCar.to(activeSlide = i.toString())">


                          </div>
                        </ng-template>
                      </ng-container>
                    </owl-carousel-o>
                  </div>
                </div>

                <ks-modal-gallery [id]="200" [modalImages]="Images" [plainGalleryConfig]="GalleryConfig"
                  [buttonsConfig]="ButtonsConfig">
                </ks-modal-gallery> 

              </div>

              <div class="col-lg-8 rtl-text">
                <div class="product-right">
                  <div class="row">
                    <div class="col-7 ">
                      <h2>{{ productName }}</h2>
                    </div>
                    <div class="col-5">
                      <h4 [ngStyle]="{'color':'var(--theme-dark)' ,'font-size':'20px'}" *ngIf="product.Code">
                        {{'code' | translate }} : {{ product.Code }}</h4>
                    </div>
                  </div>
                  <h6 class="product-price center" *ngIf="product?.Price > 0">
                    <span class="money">
                      {{'price' | translate }} : {{product?.Price | number}}
                      {{'currency' |  translate}}
                      <del class="product-before-discount" *ngIf="product?.PriceBeforeDiscount">
                        {{ product?.PriceBeforeDiscount | number }}
                        {{'currency' |  translate}}</del> </span>

                    <span *ngIf="product?.Discount" class="product-discount">
                      {{'product-saved' | translate }} :
                      {{product?.Discount | number }} {{'currency' |  translate}} </span>
                  </h6>

                  <!-- 
                  <div class="product-description border-product">
                    <h6 class="product-title size-text" *ngIf="Size(product.variants).length">
                      select size
                      <span>
                        <a href="javascrip:void(0)" (click)="SizeChart.openModal()">size chart</a>
                      </span>
                    </h6>
                    
                    <div class="size-box" *ngIf="Size(product.variants).length">
                      <ul>
                        <li [class.active]="selectedSize == size" *ngFor="let size of Size(product.variants)">
                          <a href="javascript:void(0)" (click)="selectSize(size)">{{ size | titlecase }}</a>
                        </li>
                      </ul>
                    </div>
                    <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                    <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5>
                    <h6 class="product-title">quantity</h6>
                    <div class="qty-box">
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                            <i class="ti-angle-left"></i>
                          </button>
                        </span>
                        <input type="text" name="quantity" class="form-control input-number" [value]="counter"
                          disabled />
                        <span class="input-group-prepend">
                          <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                            <i class="ti-angle-right"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div> -->

                  <div class="border-product">
                    <h6 class="product-title">{{'product-details' | translate }} </h6>
                    <p>{{ isArabic ?  product.AR_Description : product.EN_Description }}</p>
                  </div>


                  <div *ngIf="colors?.length > 0" class="border-product">
                    <h6 class="product-title">{{'colors' | translate }} </h6>

                    <div class="product-buttons" *ngFor="let current of colors">
                      <a [ngStyle]="{'background-image':'linear-gradient(30deg,' + current.Color.Code + ',50%, transparent 50%)', 'border':' 2px solid ' + current.Color.Code}"
                        [routerLink]="[isArabic ? current.Color.AR_Path : current.Color.EN_Path]" class="btn btn-solid">
                        {{ isArabic ?  current.Color.AR_Name : current.Color.EN_Name }}</a>
                    </div>
                  </div>

                  <div *ngIf="materials?.length > 0" class="border-product">
                    <h6 class="product-title">{{'materials' | translate }} </h6>

                    <div class="product-buttons" *ngFor="let current of materials">
                      <a [routerLink]="[isArabic ? current.Material.AR_Path : current.Material.EN_Path]"
                        class="btn btn-solid">
                        {{ isArabic ?  current.Material.AR_Name : current.Material.EN_Name }}</a>
                    </div>
                  </div>


                  <div *ngIf="tags?.length > 0" class="border-product">
                    <h6 class="product-title">{{'tags' | translate }} </h6>

                    <div class="product-buttons" *ngFor="let current of tags">
                      <a [routerLink]="[isArabic ? current.Tag.AR_Path : current.Tag.EN_Path]"
                        class="btn btn-solid">{{current.Tag.Name}}</a>
                    </div>
                  </div>


                  <div class="border-product">
                    <h6 class="product-title">{{'share' | translate}}</h6>
                    <div class="product-icon">
                      <app-social [path]="SocialPath"></app-social>
                    </div>
                  </div>

                  <!-- <div class="border-product">
                    <app-countdown [date]="'Apr 21 2021'"></app-countdown>
                  </div> -->

                </div>
              </div>
            </div>
          </div>

          <!-- <section class="tab-product m-0">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <ngb-tabset [type]="'tabs'" class="nav-material">
                  <ngb-tab>
                    <ng-template ngbTabTitle>
                      {{'product-details' | translate }}
                    </ng-template>

                    <ng-template ngbTabContent>
                      <p>{{ isArabic ?  product.AR_Description : product.EN_Description }}</p>
                    </ng-template>
                  </ngb-tab> -->
          <!-- <ngb-tab>

                    <ng-template ngbTabTitle>Video</ng-template>
                    <ng-template ngbTabContent>
                      <div class="mt-3 text-center">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8"
                          allow="autoplay; encrypted-media" allowfullscreen></iframe>
                      </div>
                    </ng-template>
                  </ngb-tab> -->

          <!-- <ngb-tab>
                    <ng-template ngbTabTitle> {{'write-review' | translate }} </ng-template>
                    <ng-template ngbTabContent>
                      <form class="theme-form">
                        <div class="form-row">
                          <div class="col-md-12 ">
                            <div class="media">

                              <label>Rating</label>
                              <div class="media-body ml-3">
                                <div class="rating three-star">
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                          </div>
                          <div class="col-md-6">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" id="email" placeholder="Email" required>
                          </div>
                          <div class="col-md-12">
                            <label for="review">Review Title</label>
                            <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects"
                              required>
                          </div>
                          <div class="col-md-12">
                            <label for="review">Review Title</label>
                            <textarea class="form-control" placeholder="Wrire Your Testimonial Here"
                              id="exampleFormControlTextarea1" rows="6"></textarea>
                          </div>
                          <div class="col-md-12">
                            <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                          </div>
                        </div>
                      </form>
                    </ng-template>
                  </ngb-tab> -->
          <!-- </ngb-tabset>
              </div>
            </div>
          </section> -->
        </div>




      </div>
    </div>
  </div>

  <owl-carousel-o *ngIf="relatedProducts" class="product-m space" [options]="ProductSliderConfig">
    <ng-container *ngFor="let product of relatedProducts">
      <ng-template carouselSlide>
        <div class="product-box">
          <app-product-box [product]="product.Product" [isArabic]="isArabic">
          </app-product-box>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>

  <!-- <app-related-product [type]="product?.type"></app-related-product> -->
</section>
<!-- <app-size-modal #sizeChart [product]="product"></app-size-modal> -->
<!-- Section ends -->