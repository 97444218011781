import { Component, OnInit, Input, HostListener } from '@angular/core';
import { SettingsService } from 'src/app/Services/settings.service';

@Component({
  selector: 'app-header-mobliana',
  templateUrl: './header-mobliana.component.html',
  styleUrls: ['./header-mobliana.component.scss']
})
export class HeaderMoblianaComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() isArabic: boolean;

  public stick: boolean = false;
  public newsList;

  constructor(
    public settingsService: SettingsService,
  ) {

    settingsService.language.subscribe(e => {
      this.isArabic = e == "ar";
      this.themeLogo = "assets/images/moblianaLogo.png";
    })

    const path = document.location.href;
    this.isArabic = path.indexOf("/en") <= 0;
    this.themeLogo = "assets/images/moblianaLogo.png";

    this.InitailNews();
  }

  ngOnInit(): void {
  }

  private InitailNews() {
    this.settingsService.getNews().subscribe(response => {
      this.newsList = response;
    });
  }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 250 && window.innerWidth > 200) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
