import { Component, OnInit } from '@angular/core';
import { PartModel } from 'src/app/Models/partModel';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/Services/settings.service';
import { SEOService } from 'src/app/Services/seo.service';
import { VisitorsService, Type } from 'src/app/Services/visitors.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-branch-page',
  templateUrl: './branch-page.component.html',
  styleUrls: ['./branch-page.component.scss']
})
export class BranchPageComponent implements OnInit {

  public isArabic;
  public branch: PartModel;
  public branchName: string;
  public title: string;
  lang: string;
  branchID: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    settingsService: SettingsService,
    seoService: SEOService,
    visitorService: VisitorsService) {

    this.route.paramMap.subscribe(params => {
      this.branchID = params.get('id');
      this.lang = params.get('lang');
      const name = params.get('name');
      this.isArabic = this.lang == "ar";
      this.title = this.isArabic ? settingsService.ar_Title : settingsService.en_Title;
      settingsService.ChangeDirection(this.isArabic);

      //Get Category By ID;
      settingsService.getPartByID(this.branchID).subscribe(_branch => {
        this.branch = _branch;
        this.branch.MapPath = this.sanitizer.bypassSecurityTrustResourceUrl(_branch.MapPath);

        this.branchName = this.isArabic ? this.branch.AR_Name : this.branch.EN_Name;
        var description = this.isArabic ? this.branch.AR_Description : this.branch.EN_Description;
        seoService.updateTitle(`${this.branchName} - ${this.title}`);
        seoService.updateDescription(`${description}`);

        const currentPath = `/${this.lang}/branch/${this.branchID}/${name}`;
        const path = this.isArabic ? this.branch.AR_Path : this.branch.EN_Path;
        if (currentPath !== path) {
          console.log("Not Equals");
          router.navigate([path]);
          return;
        }
        visitorService.addVisit(this.branchID, Type.Part, this.isArabic, path);
      })
    });
  }

  ngOnInit(): void {
  }

}
