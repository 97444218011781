<app-breadcrumb [title]="branchName" [breadcrumb]="title"></app-breadcrumb>
<!--section start-->
<section *ngIf="branch" class=" contact-page section-b-space">
    <div class="container">
        <h2 class="center name">{{branchName}}</h2>
        <div class="row section-b-space">
            <div class="col-lg-7 map">
                <iframe [src]="branch.MapPath" allowfullscreen></iframe>
            </div>
            <div class="col-lg-5">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/email.png" alt="Generic placeholder image">
                                <h6>{{'branch-details' |  translate}}</h6>
                            </div>
                            <div class="media-body">
                                <p class="item-detail">{{isArabic ? branch.AR_Description : branch.EN_Description}}</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <h6>{{'branch-address' | translate}}</h6>
                            </div>
                            <div class="media-body">
                                <p class="item-detail">{{isArabic ? branch.AR_Address : branch.EN_Address}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->