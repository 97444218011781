<app-breadcrumb [title]="materialName" [breadcrumb]="title"></app-breadcrumb>
<!-- section start -->
<section *ngIf="material" class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                <span class="filter-back">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i> back
                                </span>
                            </div>
                            <!-- Category filter start -->
                            <app-categories-list [isArabic]="isArabic"></app-categories-list>
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar single product slider start -->
                        <!-- <app-product-box-vertical-slider [title]="'New Products'" [categoryID]="categoryID"
                            [isArabic]="isArabic">
                        </app-product-box-vertical-slider> -->
                        <!-- side-bar single product slider end -->
                        <!-- side-bar banner start here -->
                        <!-- <div class="collection-sidebar-banner">
                            <a [routerLink]="['/shop/collection/left/sidebar']">
                                <img src="assets/images/collection-sidebar-banner.png" class="img-fluid" alt="banner">
                            </a>
                        </div> -->
                        <!-- side-bar banner end here -->
                    </div>
                </div>
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <div class="top-banner-wrapper">
                                    <a [routerLink]="['/shop/collection/left/sidebar']">
                                        <img src="assets/images/collection-banner.jpg" class="img-fluid " alt="">
                                    </a>
                                    <div class="top-banner-content small-section">
                                        <h4>fashion</h4>
                                        <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </h5>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i> Filter
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="products" [title]="materialName" [paginate]="paginate"
                                        [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)"
                                        (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                                <div class="product-box">

                                                    <app-product-box [product]="product" [isArabic]="isArabic">
                                                    </app-product-box>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                                                *ngIf="products && !products.length">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                                <h3> {{'sorry-title' | translate }} </h3>
                                                <p> {{'sorry-Description' | translate }} </p>
                                                <a [routerLink]="['/',lang]" class="btn btn-solid">
                                                    {{'sorry-home' | translate }} </a>
                                            </div>
                                        </div>
                                    </div>
                                    <app-pagination [products]="products" [paginate]="paginate"
                                        (setPage)="setPage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->