import { Component, PLATFORM_ID, Inject, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './Services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  public isArabic = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private translate: TranslateService,
    private settingsService: SettingsService,

  ) {
    const path = document.location.href;
    this.isArabic = path.indexOf("/en") <= 0;



    settingsService.language.subscribe(e => {
      this.isArabic = e == "ar";

    });
  }

  ngOnInit(): void {


    if (isPlatformBrowser(this.platformId)) {
      const lang = this.isArabic ? "ar" : "en";
      this.translate.setDefaultLang(lang);
      this.translate.addLangs(['en', 'ar']);
      // this.webSocketService.emit("onlines", 0);
    }

    // console.log("aa");
    //   this.socket.on('onlines', data => {
    //       this.settingsService.onlines.next(+da a);
    //       console.log(data);
    //   });

    //   this.socket.emit("onlines", 0);
  }
}
