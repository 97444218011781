import { Injectable, PLATFORM_ID, Inject, } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NewsModel } from '../Models/newsModel';
import { PhonesModel } from '../Models/phonesModel';
import { SliderModel } from '../Models/sliderModel';
import { PartModel } from '../Models/partModel';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public ar_Title = "موبليانا مودرن";
  public ar_HomeTitle = "للاثاث المودرن";
  public ar_Desctription = "";

  public en_Title = "Mobliana Modern Furniture";
  public en_HomeTitle = "";
  public en_Desctription = "";

  public sitePath = "https://www.moblianamodern.com"
  public Sliders;
  public News;
  public Phones;

  public language = new BehaviorSubject<string>("ar");
  public apiPath = true ? "https://api.moblianamodern.com" : "http://localhost:4000";

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private http: HttpClient,
    private router: Router,
  ) { }

  currentLangCode = "ar";
  ChangeLanguage(lang: any) {
    if (isPlatformBrowser(this.platformId)) {
      const path = document.location.href;
      const isArabic = path.indexOf("/ar") > 0;
      if (this.currentLangCode == lang)
        return;


      let newpath = path.replace(isArabic ? '/ar' : '/en', '/' + lang);
      newpath = newpath.slice(path.indexOf(isArabic ? '/ar' : '/en'), path.length);

      this.translate.use(lang)
      this.language.next(lang);
      this.currentLangCode = lang;

      if (lang.dir == 'rtl') {
        document.body.classList.remove('ltr')
        document.body.classList.add('rtl')
      } else {
        document.body.classList.remove('rtl')
        document.body.classList.add('ltr')
      }

      this.router.navigate([newpath]);
    }
  }

  ChangeDirection(isArabic: boolean) {

    if (isArabic) {
      document.body.classList.remove('ltr')
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
      document.body.classList.add('ltr')
    }
  }


  getNews(): Observable<NewsModel[]> {
    var url = `${this.apiPath}/news/select/`;
    return this.http.get<NewsModel[]>(url);
  }

  getPhones(): Observable<PhonesModel[]> {
    var url = `${this.apiPath}/phones/select/`;
    return this.http.get<PhonesModel[]>(url);
  }

  getSlider(): Observable<SliderModel[]> {
    var url = `${this.apiPath}/sliders/select/`;
    return this.http.get<SliderModel[]>(url);
  }

  getParts(): Observable<PartModel[]> {
    var url = `${this.apiPath}/parts/select/`;
    return this.http.get<PartModel[]>(url);
  }

  getPartByID(id: string): Observable<PartModel> {
    return this.http.get<PartModel>(`${this.apiPath}/parts/select/${id}`);
  }
}
