import { Component, OnInit, Input } from '@angular/core';
import { ProductModel } from 'src/app/Models/productModel';
import { NewProductSlider } from 'src/app/Services/slider';
import { ProductsService } from 'src/app/Services/products.service';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() categoryID: number; // Default Fashion
  @Input() isArabic: string;

  public products: ProductModel[] = [];
  public NewProductSliderConfig: any = NewProductSlider;

  defaultImagePath = 'assets/images/product/placeholder.jpg';
  apiPath;

  constructor(public productService: ProductsService) {
    this.apiPath = productService.apiPath + "/";
  }

  ngOnInit(): void {
    this.productService.getProductsByCategory(this.categoryID, 5, 0).subscribe(response => {
      this.products = response.rows.filter(item => item.CategoryID == this.categoryID);
    });
  }

}
