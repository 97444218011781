import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProductModel } from '../Models/productModel';
import { SettingsService } from './settings.service';
import { ColorModel } from '../Models/colorModel';
import { MaterialModel } from '../Models/materialModel';
import { TagModel } from '../Models/TagModel';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  public apiPath;
  constructor(private http: HttpClient,
    settingService: SettingsService) {
    this.apiPath = settingService.apiPath;
  }

  /*
  ---------------------------------------------
  ---------------  Products  -------------------
  ---------------------------------------------
  */
  getProducts(): Observable<ProductModel[]> {
    var url = `${this.apiPath}/products/select`;
    return this.http.get<ProductModel[]>(url);
  }

  getSpecialProducts(): Observable<ProductModel[]> {
    var url = `${this.apiPath}/products/select/special`;
    return this.http.get<ProductModel[]>(url);
  }

  public getProductsByCategory(id, limit, offset): Observable<{ count: number, rows: ProductModel[] }> {
    var url = `${this.apiPath}/products/select/byCategory/${id}/${limit}/${offset}`;
    return this.http.get<{ count: number, rows: ProductModel[] }>(url);
  }
  public getProductsByColor(id, limit, offset): Observable<{ count: number, rows: ProductModel[] }> {
    var url = `${this.apiPath}/products/select/byColor/${id}/${limit}/${offset}`;
    return this.http.get<{ count: number, rows: ProductModel[] }>(url);
  }
  public getProductsByMaterial(id, limit, offset): Observable<{ count: number, rows: ProductModel[] }> {
    var url = `${this.apiPath}/products/select/byMaterial/${id}/${limit}/${offset}`;
    return this.http.get<{ count: number, rows: ProductModel[] }>(url);
  }

  public getProductsByTag(id, limit, offset): Observable<{ count: number, rows: ProductModel[] }> {
    var url = `${this.apiPath}/products/select/byTag/${id}/${limit}/${offset}`;
    return this.http.get<{ count: number, rows: ProductModel[] }>(url);
  }

  public getProductByID(id): Observable<ProductModel> {
    return this.http.get<ProductModel>(`${this.apiPath}/products/select/byID/${id}`);
  }

  getMaterials(): Observable<MaterialModel[]> {
    var url = `${this.apiPath}/materials/select/`;
    return this.http.get<MaterialModel[]>(url);
  }
  getMaterialByID(id: string): Observable<MaterialModel> {
    return this.http.get<MaterialModel>(`${this.apiPath}/materials/select/byID/${id}`);
  }

  getColors(): Observable<ColorModel[]> {
    var url = `${this.apiPath}/colors/select/`;
    return this.http.get<ColorModel[]>(url);
  }
  getColorByID(id: string): Observable<ColorModel> {
    return this.http.get<ColorModel>(`${this.apiPath}/colors/select/byID/${id}`);
  }

  getTags(): Observable<TagModel[]> {
    var url = `${this.apiPath}/tags/select/`;
    return this.http.get<TagModel[]>(url);
  }
  getTagByID(id: string): Observable<TagModel> {
    return this.http.get<TagModel>(`${this.apiPath}/tags/select/byID/${id}`);
  }
  getTagByName(name: string): Observable<TagModel> {
    return this.http.get<TagModel>(`${this.apiPath}/tags/select/byName/${name}`);
  }
  // Get Product Filter
  public filterProducts(products: Observable<ProductModel[]>, filter: any): Observable<ProductModel[]> {
    return products
      .pipe(map(product =>
        product.filter((item: ProductModel) => {
          if (!filter.length) return true

          const Tags = filter.some((prev) => { // Match Tags
            if (item.AR_Tags) {
              if (item.AR_Tags.includes(prev)) {
                return prev
              }
            }
          })
          return Tags
        })
      ));
  }


  // Sorting Filter
  public sortProducts(products: ProductModel[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.ProductID < b.ProductID) {
          return -1;
        } else if (a.ProductID > b.ProductID) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.AR_Name < b.AR_Name) {
          return -1;
        } else if (a.AR_Name > b.AR_Name) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.AR_Name > b.AR_Name) {
          return -1;
        } else if (a.AR_Name < b.AR_Name) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.Price < b.Price) {
          return -1;
        } else if (a.Price > b.Price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.Price > b.Price) {
          return -1;
        } else if (a.Price < b.Price) {
          return 1;
        }
        return 0;
      })
    }
  }


  /*
  ---------------------------------------------
  ------------- Product Pagination  -----------
  ---------------------------------------------
*/
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
