<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div>
        <a
          href="https://api.whatsapp.com/send?phone=+201210036100&text=I%27m%20interested%20in%20this%20product%20and%20I%20have%20a%20few%20questions.%20Can%20you%20help%3f&source=&data=&app_absent=">
          <!-- <img class="img-fluid" src="assets/images/icon/whatsapp-35.png"> -->
          <i class="fa fa-whatsapp whats-icon" aria-hidden="true"></i>
        </a>

        <!-- <img src="assets/images/icon/whatsapp-35.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
        <ul class="show-div shopping-cart">
          <h5></h5>
        </ul> -->

      </div>
    </li>

    <li class="onhover-div mobile-search  search-widgets">
      <div>
        <img src="assets/images/icon/white-phone-35.png" class="img-fluid" alt="">
        <i class="ti-comment-alt"></i>
      </div>
      <ul class="show-div shopping-cart slide-down-container">
        <li class="phone-li" *ngFor="let phone of phonesList">
          <a [href]="phone.Path">
            {{ phone.Phone }}
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </li>

    <li class="onhover-div mobile-setting ">
      <div><img src="assets/images/icon/white-language-35.png" class="img-fluid" alt="setting">
        <i class="ti-world"></i></div>
      <div class="show-div shopping-cart  slide-down-container">
        <!-- <h6 class="center">
          {{ 'language' | translate }}
        </h6> -->
        <ul>
          <li class="lang-li" *ngFor="let language of languages">
            <a (click)="changeLanguage(language)">
              {{ language.name }}
            </a>
          </li>
        </ul>
      </div>
    </li>

  </ul>
</div>