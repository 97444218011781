import { Component, OnInit, Input } from "@angular/core";
import { Menu, NavService } from "src/app/Services/nav.service";
import { PartModel } from "src/app/Models/partModel";
import { SettingsService } from "src/app/Services/settings.service";
import { CategoryService } from "src/app/Services/category.service";
import { CategoryModel } from "src/app/Models/categoryModel";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() class: string = "footer-light"; // Default class
  @Input() themeLogo: string = "assets/images/moblianaLogo.png"; // Default Logo
  @Input() newsletter: boolean = true; // Default True
  @Input() isArabic: boolean;

  public menuItems: Menu[];
  public partsList: PartModel[] = [];
  public categoryList: CategoryModel[] = [];

  public today: number = Date.now();
  public onlines = 0;

  constructor(
    public navServices: NavService,
    public settingsService: SettingsService,
    public categoryService: CategoryService
  ) {
    //Get Footer Items;
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
    });

    this.InitailParts();
    this.InitailCategories();
    this.onlines = Math.floor(Math.random() * 8) + 3;
  }

  private InitailParts() {
    this.settingsService.getParts().subscribe((response) => {
      this.partsList = response;
    });
  }

  private InitailCategories() {
    this.categoryService.getCategories().subscribe((response) => {
      var parents = response.filter((e) => e.ParentID == 0);

      parents.forEach((parent) => {
        if (this.categoryList.length > 1) return;
        var childes = response.filter((e) => e.ParentID == parent.CategoryID);
        if (childes.length > 0) {
          parent.Childes = childes;
          this.categoryList.push(parent);
        }
      });
    });
  }

  ngOnInit(): void {}
}
