import { Component, OnInit, Input } from '@angular/core';
import { CollectionSlider } from '../../../Services/slider';
import { CategoryModel } from 'src/app/Models/categoryModel';

@Component({
  selector: 'app-category-widget',
  templateUrl: './category-widget.component.html',
  styleUrls: ['./category-widget.component.scss']
})
export class CategoryWidgetComponent implements OnInit {
  @Input() categories: CategoryModel[];
  @Input() isArabic: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  public CollectionSliderConfig: any = CollectionSlider;
}
