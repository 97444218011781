<owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
    <ng-container *ngFor="let cats of categories">
      <ng-template carouselSlide>
        <div>
          <div class="category-wrapper">
            <div>
              <img [src]="cats.ImageUrl" class="img-fluid w-auto category-image" alt="">
              <h4 class="category-title">{{isArabic ? cats.AR_Name : cats.EN_Name}}</h4>
              <p class="center category-description">{{ isArabic ? cats.AR_Description : cats.EN_Description }}</p>
              <a [routerLink]="isArabic ? cats.AR_Path : cats.EN_Path" class="btn btn-outline">
                {{ 'more' | translate }}
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>