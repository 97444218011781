<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container>
      <ng-template carouselSlide>
        <div>
          <div *ngFor="let product of products | slice:0:3 ">
            <a [routerLink]="product.Path">
              <img class="img-fluid w-auto" [defaultImage]="defaultImagePath" [lazyLoad]="product.ImageUrl" alt="">
            </a>
            <div class="media-body align-self-center">

              <a [routerLink]="product.Path">
                <h4 class="product-title center">
                  {{ (isArabic ? product?.AR_Name : product?.EN_Name ) | titlecase }}
                </h4>
              </a>

            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->