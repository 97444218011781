import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryService } from './category.service';
import { SettingsService } from './settings.service';

// Menu
export interface Menu {
	CategoryID?: number;
	AR_Name?: string;
	EN_Name?: string;
	AR_Path?: string;
	EN_Path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {
	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	constructor(
		private categoryService: CategoryService,
		private settingsService: SettingsService
	) {

		this.InitailParts();

		//Get Parts
	}

	private InitailParts() {
		this.settingsService.getParts().subscribe(response => {

			let parts: Menu = this.MENUITEMS.find(e => e.EN_Name === "Branches");
			if (parts) return;


			parts = {
				CategoryID: 0,
				AR_Name: 'فروعنا',
				EN_Name: 'Branches',
				type: 'sub',
				megaMenu: false,
				badge: false,
				active: false,
				children: []
			}

			let childrens = response;
			childrens.forEach(child => {
				parts.children.push({
					AR_Name: child.AR_Name,
					EN_Name: child.EN_Name,
					AR_Path: child.AR_Path,
					EN_Path: child.EN_Path,
					megaMenu: false,
					type: 'link',
				})
			})

			this.InitailCategories(parts);
		});
	}

	private InitailCategories(parts) {
		//Get Categories
		this.categoryService.getCategories().subscribe(response => {
			let cate: Menu = this.MENUITEMS.find(e => e.EN_Name === "Categories");
			if (cate) return;

			cate = {
				CategoryID: 0,
				AR_Name: 'الاقسام',
				EN_Name: 'Categories',
				type: 'sub',
				megaMenu: true,
				badge: true,
				badgeText: 'sale',
				active: true,
				children: []
			}

			var about = {
				CategoryID: 0,
				AR_Name: 'عن الشركة',
				EN_Name: 'About US',
				type: 'link',
				megaMenu: false,
				AR_Path: '/ar',
				EN_Path: '/en',
				active: true,
			}

			var home = {
				CategoryID: 0,
				AR_Name: 'الرئيسية',
				EN_Name: 'Home',
				type: 'link',
				megaMenu: false,
				AR_Path: '/ar',
				EN_Path: '/en',
				active: true,
			}

			var language = {
				CategoryID: 0,
				AR_Name: 'اللغة',
				EN_Name: 'Language',
				type: 'sub',
				megaMenu: false,
				active: true,
				children: [{
					CategoryID: 0,
					AR_Name: "عربي",
					EN_Name: "Arabic",
					megaMenu: true,
					type: 'action',
					Action: () => this.settingsService.ChangeLanguage("ar"),
				},
				{
					CategoryID: 0,
					AR_Name: "إنجليزي",
					EN_Name: "English",
					megaMenu: true,
					type: 'action',
					Action: () => this.settingsService.ChangeLanguage("en"),
				},
				]
			}

			var contact = {
				CategoryID: 0,
				AR_Name: 'اتصل بنا',
				EN_Name: 'Contact US',
				type: 'sub',
				megaMenu: false,
				active: true,
				children: []
			}

			const parents = response.filter(e => e.ParentID == 0 && e.ShowInHeader);
			parents.forEach(parent => {
				let newParent: Menu = {
					CategoryID: parent.CategoryID,
					AR_Name: parent.AR_Name,
					EN_Name: parent.EN_Name,
					type: 'sub',
					children: [],
					active: false,
					badgeText: 'new',
				}

				let childrens = response.filter(e => e.ParentID == parent.CategoryID);

				childrens.forEach(child => {
					newParent.children.push({
						CategoryID: child.CategoryID,
						AR_Name: child.AR_Name,
						EN_Name: child.EN_Name,
						AR_Path: child.AR_Path,
						EN_Path: child.EN_Path,

						megaMenu: true,
						type: 'link',
					})
				})

				cate.children.push(newParent);
			});

			this.settingsService.getPhones().subscribe(response => {
				response.forEach(element => {
					contact.children.push({
						CategoryID: 0,
						AR_Name: element.Phone,
						EN_Name: element.Phone,
						megaMenu: true,
						type: 'extTabLink',
						AR_Path: `https://api.whatsapp.com/send?phone=+2${element.Phone}&text=I%27m%20interested%20in%20this%20product%20and%20I%20have%20a%20few%20questions.%20Can%20you%20help%3f&source=&data=&app_absent=`,
						EN_Path: `https://api.whatsapp.com/send?phone=+2${element.Phone}&text=I%27m%20interested%20in%20this%20product%20and%20I%20have%20a%20few%20questions.%20Can%20you%20help%3f&source=&data=&app_absent=`,

						// pat
						// Action: () =>
						// {
						// 	phone.Path = `https://api.whatsapp.com/send?phone=+2${element.Phone}&text=I%27m%20interested%20in%20this%20product%20and%20I%20have%20a%20few%20questions.%20Can%20you%20help%3f&source=&data=&app_absent=`;
						// },
					})
				});;
			});

			// this.MENUITEMS.push(home);
			this.MENUITEMS.push(cate);
			this.MENUITEMS.push(parts);
			this.MENUITEMS.push(language);
			this.MENUITEMS.push(contact);
			this.MENUITEMS.push(about);


		});
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		// {
		// 	title: 'فروعنا', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'دمياط', type: 'sub', active: false, children: [
		// 				{ path: '/home/fashion', title: 'دمياط الجديدة', type: 'link' },
		// 				{ path: '/home/fashion-2', title: 'دمياط', type: 'link' },
		// 				{ path: '/home/fashion-3', title: 'الشعراء', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/home/vegetable', title: 'الاسكندرية', type: 'link' },
		// 		{ path: '/home/watch', title: 'القاهره', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Products', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'sidebar', type: 'sub', active: false, children: [
		// 				{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
		// 				{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
		// 				{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/shop/product/three/column/trim-dress', title: 'three-column', type: 'link' },
		// 		{ path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
		// 		{ path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
		// 		{ path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }
		// 	]
		// },
	];



	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
