import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProductModel } from 'src/app/Models/productModel';
import { ProductQuickViewComponent } from '../product-quick-view/product-quick-view.component';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {

  @Input() product: ProductModel;
  @Input() thumbnail: boolean = false; // Default False 
  @Input() loader: boolean = false;
  @Input() isArabic: string;

  @ViewChild("quickView") QuickView: ProductQuickViewComponent;

  public ImageSrc = 'assets/images/product/placeholder.jpg';
  constructor() {}

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 1000); // Skeleton Loader
    }
  }

  like() {
  }
}
