<div class="product-pagination" *ngIf="products && products.length">
    <div class="theme-paggination-block">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <nav aria-label="Page navigation">
                        <ul class="pagination" *ngIf="paginate.pages && paginate.pages.length">
                            <li class="page-item" [ngClass]="{disabled:paginate.currentPage === 1}">
                                <a href="javascript:void(0)" class="page-link"
                                    (click)="pageSet(paginate.currentPage - 1)" aria-label="Previous">
                                    <span aria-hidden="true"><i class="fa fa-chevron-left"
                                            aria-hidden="true"></i></span>
                                    <span class="sr-only">{{'previous' | translate }}</span>
                                </a>
                            </li>
                            <li class="page-item active" *ngFor="let page of paginate.pages"
                                [ngClass]="{ active: paginate.currentPage == page }">
                                <a href="javascript:void(0)" class="page-link" (click)="pageSet(page)">{{ page }}</a>
                            </li>
                            <li class="page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
                                <a href="javascript:void(0)" class="page-link"
                                    (click)="pageSet(paginate.currentPage + 1)" aria-label="Next">
                                    <span aria-hidden="true"><i class="fa fa-chevron-right"
                                            aria-hidden="true"></i></span>
                                    <span class="sr-only">{{'next' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="product-search-count-bottom">
                        <h5>{{'showing-products' | translate }} 1-{{ products.length }} {{'of' | translate }}
                            {{ paginate.totalItems }} {{'result' | translate }} </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>