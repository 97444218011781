import { Component, OnInit, Injectable, PLATFORM_ID, Inject, Input } from '@angular/core';
import { PhonesModel } from 'src/app/Models/phonesModel';
import { SettingsService } from 'src/app/Services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public phonesList: PhonesModel[] = []

  public languages = [{
    name: 'English',
    code: 'en',
    dir: 'ltr'
  }, {
    name: 'اللغة العربية',
    code: 'ar',
    dir: 'rtl'
  }];


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    public settingsService: SettingsService,) {
    this.InitailSliders();
  }

  ngOnInit(): void {
  }

  private InitailSliders() {
    this.settingsService.getPhones().subscribe(response => {
      this.phonesList = response;

      this.phonesList.forEach(element => {
        element.Path = `https://api.whatsapp.com/send?phone=+2${element.Phone}&text=I%27m%20interested%20in%20this%20product%20and%20I%20have%20a%20few%20questions.%20Can%20you%20help%3f&source=&data=&app_absent=`;
      });
    });
  }

  changeLanguage(lang) {
    this.settingsService.ChangeLanguage(lang);
  }

}
