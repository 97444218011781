<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse"> {{'Categories' |  translate}} </h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let category of categories">
                    <a [routerLink]="[isArabic? category.AR_Path : category.EN_Path ]">
                        {{ isArabic ? category?.AR_Name : category?.EN_Name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->