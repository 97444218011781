<h3 class="center online">
  {{ "online" | translate }} : <span>{{ onlines }}</span>
</h3>

<p class="center flags">
  <a href="http://info.flagcounter.com/5WoL"
    ><img
      src="http://s09.flagcounter.com/count/5WoL/bg_FFFFFF/txt_000000/border_CCCCCC/columns_3/maxflags_12/viewers_0/labels_1/pageviews_1/flags_0/"
      alt="Flag Counter"
  /></a>
</p>

<!--footer section -->
<footer [class]="class">
  <!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
  <section class="section-b-space light-layout">
    <div class="container">
      <div class="row footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          <div class="footer-title footer-mobile-title">
            <h4>{{ "about-title" | translate }}</h4>
          </div>

          <div class="footer-contant">
            <div class="footer-logo">
              <div class="logo-text">MADE IN DAMIETTA</div>
            </div>
            <p class="center">{{ "about" | translate }}</p>
            <p class="center">{{ "about2" | translate }}</p>
            <div class="footer-social">
              <ul>
                <li>
                  <a
                    style="color: #3b5998"
                    href="https://www.facebook.com/moblianamodern/"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    style="color: #c4302b"
                    href="https://www.youtube.com/channel/UCIlifLg5mPPgM_FBA_mOU2g"
                    ><i class="fa fa-youtube-play" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a style="color: #1da1f2" href="javascript:void(0)"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    style="color: #3f729b"
                    href="https://www.instagram.com/moblianamodern"
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a style="color: #ee802f" href="javascript:void(0)"
                    ><i class="fa fa-rss" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4 >
								{{ 'parts' | translate }}
							</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li *ngFor="let part of partsList">
									<a href="javascript:void(0)">
										{{isArabic ? part.AR_Name: part.EN_Name}}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div> -->
        <div class="col-md-3 offset-xl-1">
          <div class="sub-title">
            <div class="footer-title">
              <h4>{{ "parts" | translate }}</h4>
            </div>
            <div class="footer-contant">
              <ul *ngFor="let part of partsList">
                <li>
                  <a [routerLink]="isArabic ? part.AR_Path : part.EN_Path">{{
                    isArabic ? part.AR_Name : part.EN_Name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-md-3 offset-xl-1 parent"
          *ngFor="let cate of categoryList | slice: 0:1"
        >
          <div class="sub-title">
            <div class="footer-title">
              <h4>{{ isArabic ? cate.AR_Name : cate.EN_Name }}</h4>
            </div>
            <div class="footer-contant">
              <ul *ngFor="let child of cate.Childes | slice: 0:6">
                <li>
                  <a [routerLink]="isArabic ? child.AR_Path : child.EN_Path">{{
                    isArabic ? child.AR_Name : child.EN_Name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="col"> -->
        <!-- <div class="sub-title">
						<div class="footer-title">
							<h4>store information</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li><i class="fa fa-map-marker">

								</i>Some Details</li>
								<li><i class="fa fa-phone"></i>Call Us: 01060707121</li>
								<li><i class="fa fa-envelope-o"></i>Email Us: <a>support@MoblianaModern.com</a></li>
							</ul>
						</div>
					</div> -->
        <!-- </div> -->
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date: "y" }}
              {{ "copy-right" | translate }}
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <!-- <div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div> -->
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->
