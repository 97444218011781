import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SettingsService } from 'src/app/Services/settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() header: string = "Mob"; // Default false
  @Input() isArabic: boolean;

  public stick: boolean = false;
  public newsList;

  constructor(
    public settingsService: SettingsService,
  ) {
    this.InitailNews();
  }

  ngOnInit(): void {
  }

  private InitailNews() {
    this.settingsService.getNews().subscribe(response => {
      this.newsList = response;
    });
  }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 250 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
