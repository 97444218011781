<ngx-loading-bar></ngx-loading-bar>



<app-header-mobliana [sticky]="true" [isArabic]="isArabic"></app-header-mobliana>
<router-outlet></router-outlet>
<app-footer [isArabic]="isArabic"></app-footer>

<app-tap-to-top></app-tap-to-top>

<div class="whats-app">
  <a
    href="https://api.whatsapp.com/send?phone=+201210036100&text=I%27m%20interested%20in%20this%20product%20and%20I%20have%20a%20few%20questions.%20Can%20you%20help%3f&source=&data=&app_absent=">
    <img class="wa-chat-btn-icon-image-only" src="assets/images/icon/whats-app.webp">
  </a>
</div>

<div class="whats-app facebook">
  <a href="https://www.facebook.com/moblianamodern/">
    <img class="wa-chat-btn-icon-image-only" src="assets/images/icon/face.png">
  </a>
</div>