<ul class="product-social">
  <li>
    <a style="color:#3b5998" [href]="'https://www.facebook.com/sharer.php?u='+path">
      <i class="fa fa-facebook"></i>
    </a>
  </li>
  <li>
    <a style="color:#1DA1F2" [href]="'https://twitter.com/share?url='+path">
      <i class="fa fa-twitter"></i>
    </a>
  </li>

  <li>
    <a style="color:#11887A" [href]="'https://wa.me/?text='+path">
      <i class="fa fa-whatsapp"></i>
    </a>
  </li>

  <li>
    <a style="color:#0e76a8" [href]="'https://www.linkedin.com/sharing/share-offsite/?url='+path">
      <i class="fa fa-linkedin"></i>
    </a>
  </li>

  <li>
    <a style="color:#E60023" [href]="'http://pinterest.com/pin/create/link/?url='+path">
      <i class="fa fa-pinterest"></i>
    </a>
  </li>
</ul>